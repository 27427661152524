import { render, staticRenderFns } from "./BalanceAdd.vue?vue&type=template&id=4a57b0c2&scoped=true&"
import script from "./BalanceAdd.vue?vue&type=script&lang=js&"
export * from "./BalanceAdd.vue?vue&type=script&lang=js&"
import style0 from "./BalanceAdd.vue?vue&type=style&index=0&id=4a57b0c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a57b0c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCheckbox,VDialog,VTextField})
