<template>
  <div>
    <div class="table">
      <div v-if="!server">
        <v-data-table v-model="selected" @input="enterSelect()" locale="ru-RU" checkbox-color="#FEE600"
                      :loading="loading" :page.sync="page" :custom-sort="customSort" :headers="processedHeaders"
                      :show-select="!hideselect" :items="tableData" :itemsPerPage="localPerPage"
                      @page-count="pageCount = $event"
                      hide-default-footer no-data-text="Нет данных" item-key="id" class="elevation-1"
                      loading-text="Загружаем данные таблицы">

          <template #progress>
            <v-progress-linear indeterminate color="green"></v-progress-linear>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="status_wait" v-if="item.status === 'wait'">В процессе</div>
            <div class="status_end" v-if="item.status === '1'">Завершен</div>
            <div class="status_success" v-if="item.status === 'work'">Включен</div>
            <div class="status_stop" v-if="item.status === 'stop'">Остановлен</div>
            <div class="status_success" v-if="item.status === 'success'">Одобрено</div>
            <div class="status_wait" v-if="item.status === 'waiting'">Ждет проверки</div>
            <div class="status_stop" v-if="item.status === 'cancelled'">Отказано</div>
          </template>

          <template v-slot:[`item.sender_status`]="{ item }">
            <div class="status_success" v-if="item.sender_status === 'process'">Рассылается</div>
            <div class="status_wait" v-if="item.sender_status === 'pause'">Пауза</div>
            <div class="status_stop" v-if="item.sender_status === 'stop'">Остановлено</div>
          </template>

          <template v-slot:[`item.sender_type`]="{ item }">
            <div class="status_stop" v-if="item.sender_type === 'manual'">Ручная</div>
            <div class="status_success" v-if="item.sender_type === 'api'">API</div>
          </template>

          <template v-slot:[`item.user_status`]="{ item }">
            <div class="status_wait" v-if="item.user_status === 'waiting'">Ждет проверки</div>
            <div class="status_success" v-if="item.user_status === 'active'">Активен</div>
            <div class="status_stop" v-if="item.user_status === 'unactive'">Не активен</div>
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div v-if="item.operator === 'МТС'">qqq
              <Icon name="op_mts"/>
            </div>
          </template>

          <template v-slot:[`item.status_pay`]="{ item }">
            <div class="status_wait" v-if="item.status_pay === 'waiting'">Ждет проверки</div>
            <div class="status_success" v-if="item.status_pay === 'success'">Успешно</div>
            <div class="status_stop" v-if="item.status_pay === 'cancelled'">Отказано</div>
          </template>

          <template v-slot:[`item.Pcosent_Convesion_Target`]="{ item }">
            <div>{{ item.Pcosent_Convesion_Target }} %</div>
          </template>

          <template v-slot:[`item.target_limit`]="{ item }">
            <div class="target_container" v-if="item.target_on">
              <div class="target_des">{{ item.limit }}/{{ item.target }}</div>
              <div @click='$emit("clearTarget", item)' v-if="item.target_rate > 0" class="target">
                <Icon name="delete"/>
              </div>
            </div>
            <div v-else>---</div>
          </template>

          <template v-slot:[`item.limit`]="{ item }">
            <div>{{ '6< ' + item }}</div>
          </template>

          <template v-slot:[`item.target_price`]="{ item }">
            <div class="target_container" v-if="item.target_on && item.target > 0">
              <div class="target_des">{{ (item.target_rate / item.target).toFixed(2) }} р.</div>
            </div>
            <div v-else>---</div>
          </template>


          <template v-slot:[`item.nums`]="{ item }">
            <div>{{ item.contacts }}/{{ item.call }}</div>
          </template>

          <template v-slot:[`item.url`]="{ item }">
            <div>
              https://gate.crmcalls.ru/api/web/u{{
                $store.getters[' user/getName'].replace(/[^0-9]/g, "")
              }}/{{ item.guid }}
            </div>
          </template>


          <template v-slot:[`item.unique`]="{ item }">
            <div v-if="item.unique == 1">Да</div>
            <div v-else>Нет</div>
          </template>

          <template v-slot:[`item.date`]="{ item }">
            <div class="table_text">{{ item.date }}</div>
          </template>
          <template v-slot:[`item.sum`]="{ item }">
            <div class="table_text" :class="numClass(item.sum)">{{ item.sum }}</div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div class="table_text">{{ item.phone }}</div>
          </template>

          <template v-slot:[`item.callname`]="{ item }">
            <div>
              <div class="auto" v-if="item.currentType == 'auto'">авто</div>
              {{ item.name }}
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">

            <v-menu v-if="items.length > 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="props" v-bind="attrs" v-on="on">
                  <Icon name="props"/>
                </div>
              </template>

              <v-list>
                <div v-for="(menu_item, index) in items" :key="index" @click="$emit(menu_item.action, item)">

                  <v-list-item v-if="item.status == 'stop' || menu_item.action != 'play'">
                    <v-list-item-title :class="itemClass(menu_item.title)">
                      {{ itemText(menu_item, item) }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
            <div v-else :class="itemClass(items[0].title)" @click="$emit(items[0].action, item)">
              {{ items[0].title }}
            </div>
          </template>
        </v-data-table>
        <!-- <v-pagination color="#E9EAEA" class="my-4" :total-visible="7" v-model="page"
                        :length="pageCount"></v-pagination> -->
        <div class="pagination-wrapper">
          <v-pagination color="#E9EAEA" class="my-4" :total-visible="7" v-model="page" :length="pageCount">
          </v-pagination>
          <div class="items-per-page">
            <span>Элементов на странице: {{ localPerPage }}</span>
            <v-select v-model="localPerPage" :items="itemsPerPageOptions" dense outlined hide-details
                      class="per-page-select" @change="handlePerPageChange"></v-select>
          </div>
        </div>
      </div>
      <div v-if="server">
        <v-data-table v-model="selected" @input="enterSelect()" :loading="loading"
                      :server-items-length="Number(pageCount_server)" :page.sync="server_page" :options.sync="options"
                      checkbox-color="#FEE600" :show-select="!hideselect" locale="ru-RU" no-data-text="Нет данных"
                      :headers="headers" :itemsPerPage="perPage" :items="data" hide-default-footer item-key="id"
                      class="elevation-1"
                      loading-text="Загружаем данные таблицы">
          <template #progress>
            <v-progress-linear indeterminate color="green"></v-progress-linear>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu v-if="items.length > 1 && item.status != 'off'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="props" v-bind="attrs" v-on="on">
                  <Icon name="props"/>
                </div>
              </template>

              <v-list>
                <div v-for="(menu_item, index) in items" :key="index" @click="$emit(menu_item.action, item)">
                  <v-list-item>
                    <v-list-item-title :class="itemClass(menu_item.title)">
                      {{ itemText(menu_item, item) }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
            <div v-else :class="itemClass(items[0].title)" @click="$emit(items[0].action, item)">
              {{ items[0].title }}
            </div>
          </template>
          <template v-slot:[`item.n`]="{ item }">
            <v-tooltip top @click="copy(item.id)">
              <template v-slot:activator="{ on, attrs }">
                <div class="table_text" v-bind="attrs" v-on="on">
                  {{ item.n }}
                </div>
              </template>
              <span>{{ item.id }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="status_wait" v-if="item.status == 'wait'">Одобрено</div>
            <div class="status_end" v-if="item.status == '1'">Отклонено</div>
            <div class="status_uncall" v-if="item.status == '2'">недозвон</div>
            <div class="status_success" v-if="item.status == 'success'">Успешно</div>
            <div class="status_stop" v-if="item.status == '4'">Не доставлено</div>
            <div class="status_success" v-if="item.status == '10'">Доставлено</div>
            <div class="status_stop" v-if="item.status == 'stop'">Остановлено</div>
            <div class="status_success" v-if="item.status == 'active'">Рассылается</div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="status_wait" v-if="item.type == 'manual'">Ручная</div>
            <div class="status_success" v-if="item.type == 'api'">API</div>
          </template>
          <template v-slot:[`item.statusAlpha`]="{ item }">
            <div class="status_wait" v-if="item.status == 'wait'">На рассмотрении</div>
            <div class="status_cancel" v-if="item.status == 'cancel'">Отклонено</div>
            <div class="status_success" v-if="item.status == 'success'">Успешно</div>
            <div class="status_cancel" v-if="item.status == 'off'">Отключено</div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div>{{ new Date(item.created) }}</div>
          </template>
          <template v-slot:[`item.updated`]="{ item }">
            <div>{{ new Date(item.updated) }}</div>
          </template>

          <template v-slot:[`item.operator`]="{ item }">
            <div class="operator_ico" v-if="item.operator == 'МТС'">
              <Icon name="op_mts"/>
              МТС
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Билайн'">
              <Icon name="op_beeline"/>
              Билайн
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Мегафон'">
              <Icon name="op_megafon"/>
              Мегафон
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Tele2'">
              <Icon name="op_tele"/>
              Tele2
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Тинькофф Мобайл'">
              <Icon name="op_tinkoff"/>
              Тинькофф Мобайл
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Yota'">
              <Icon name="op_yota"/>
              Yota
            </div>
            <div class="operator_ico" v-else-if="item.operator == 'Ростелеком'">
              <Icon name="op_rst"/>
              Ростелеком
            </div>
            <div class="operator_ico" v-else>
              <Icon name="op_other"/>
              {{ item.operator ? item.operator : "Не определено" }}
            </div>
          </template>
          <template v-slot:[`item.text`]="{ item }">
            <div class="table_text" :class="{ 'status_uncall': textFormat(item.text) == 'Нет данных' }">
              {{ textFormat(item.text) }}
            </div>
          </template>
          <template v-slot:[`item.phoneB`]="{ item }">
            <div class="table_text">
              {{
                '+' + item.phoneB.substr(0, 1) + ' (' + item.phoneB.substr(1, 3) + ') ' + item.phoneB.substr(4, 3) + '-' +
                item.phoneB.substr(7, 2) + '-' + item.phoneB.substr(9, 2)
              }}
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div class="table_text">
              {{
                '+' + item.phone.substr(0, 1) + ' (' + item.phone.substr(1, 3) + ') ' + item.phone.substr(4, 3) + '-' +
                item.phone.substr(7, 2) + '-' + item.phone.substr(9, 2)
              }}
            </div>
          </template>
        </v-data-table>
        <v-pagination color="#E9EAEA" class="my-4" :total-visible="7" v-model="server_page"
                      :length="Math.ceil(pageCount_server / perPage)"></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '../SetIcon.vue'

export default {
  props: ['headers', 'items', 'hideselect', 'data', 'perPage', 'callback_edit', 'callback_stop', 'callback_refresh',
    'callback_view', 'callback_play', 'callback_delete', 'server', 'pageCount_server', 'loading', 'callback_draw', 'page_type'
  ],
  components: {
    Icon,
  },
  data() {
    return {
      page: 1,
      edit_popup: false,
      options: {},
      server_page: 1,
      selected: [],
      localPerPage: 25,
      itemsPerPageOptions: [10, 25, 50, 100],
      SumIgnoreColumns: ['Процент доставки'],
      AvgIgnoreColumns: ['']
    }
  },
  watch: {
    options: {
      handler() {
        this.serverSort()
      },
      deep: true,
    },
    data: {
      handler() {
        this.pageCount = Math.ceil(this.data.length / this.localPerPage);
      },
      immediate: true
    }
  },
  beforeDestroy() {
    let data = {
      hide: true,
      data: [],
      page_type: "none",
      cb_refresh: false,
      cb_clear: false
    }
    this.$store.commit('notification/setFooterState', data)
  },
  methods: {
    customSort(items, index, isDesc) {
      // Отделяем итоговые строки
      const totalRow = items.find(item => item[this.headers[0].value] === 'ИТОГО');
      const averageRow = items.find(item => item[this.headers[0].value] === 'Ср. Знач.');

      // Фильтруем основные данные (без итоговых строк)
      const regularItems = items.filter(item =>
          item[this.headers[0].value] !== 'ИТОГО' && item[this.headers[0].value] !== 'Ср. Знач.'
      );

      // Сортируем только основные данные, если они есть
      if (regularItems.length > 0) {
        regularItems.sort((a, b) => {
          if (index === "target_price") {
            let priceA = 0;
            let priceB = 0;
            if (a["target_on"] && a.target > 0) priceA = (a.target_rate / a.target).toFixed(2);
            if (b["target_on"] && b.target > 0) priceB = (b.target_rate / b.target).toFixed(2);

            if (isDesc === 'false') return Number(priceA) < Number(priceB) ? -1 : 1;
            else return Number(priceB) < Number(priceA) ? -1 : 1;
          }

          if (isDesc === 'false') return a[index] < b[index] ? -1 : 1;
          else return b[index] < a[index] ? -1 : 1;
        });
      }

      // Возвращаем массив, всегда включая итоговые строки
      return [
        ...regularItems,
        ...[totalRow, averageRow].filter(row => row !== undefined)
      ];
    },
    calculateTotalRow() {
      let totalRow = {}

      totalRow[this.headers[0].value] = 'ИТОГО';

      this.headers.forEach(header => {
        if (this.isNumericColumn(header) && !this.SumIgnoreColumns.includes(header.text)) {
          totalRow[header.value] = this.data.reduce((sum, item) => {
            const value = parseFloat(item[header.value]) || 0;
            return sum + value
          }, 0);
        } else if (this.SumIgnoreColumns.includes(header.text)) {
          totalRow[header.value] = "Без подсчета";
        } else {
          if (this.headers[0].value !== header.value) {
            totalRow[header.value] = "Не число";
          }
        }
      });

      Object.keys(totalRow).map(column => {
        totalRow[column] = typeof totalRow[column] === 'number' ? +(totalRow[column].toFixed(2)) : totalRow[column]
      })
      return totalRow;
    },

    calculateAverageRow() {
      let averageRow = {}

      averageRow[this.headers[0].value] = 'Ср. Знач.';

      // Вычисляем средние значения по каждому столбцу
      this.headers.forEach(header => {
        if (this.isNumericColumn(header) && !this.AvgIgnoreColumns.includes(header.text)) {
          const sum = this.data.reduce((acc, item) => {
            const value = parseFloat(item[header.value]) || 0;
            return acc + value;
          }, 0);
          averageRow[header.value] = (sum / this.data.length).toFixed(2);
        }
      });

      Object.keys(averageRow).map(column => {
        averageRow[column] = typeof averageRow[column] === 'number' ? +(averageRow[column].toFixed(2)) : averageRow[column]
      })

      return averageRow;
    },

    isNumericColumn(header) {
      // Проверяем первое непустое значение в колонке
      const firstItem = this.data.find(item => item[header.value] !== undefined && item[header.value] !== null);
      if (!firstItem) return false;

      const value = firstItem[header.value];

      // Проверяем является ли значение числом или строкой с числом
      if (typeof value === 'number') return true;
      if (typeof value === 'string') {
        // Исключаем телефонные номера (содержат более 5 цифр подряд)
        if (/\d{6,}/.test(value)) return false;

        // Проверяем, можно ли преобразовать в число
        const parsed = parseFloat(value.replace(/[^-0-9.]/g, ''));
        return !isNaN(parsed);
      }
      return false;
    },

    handlePerPageChange() {
      this.page = 1; // Сброс на первую страницу
      this.pageCount = Math.ceil(this.data.length / this.localPerPage);
    },
    getHeaderWithTotal(header) {
      if (!this.isNumericColumn(header.value) || !this.data || this.data.length === 0) {
        return header.text;
      }
      if (['Процент доставки', 'Процент кликов'].includes(header.text)) {
        return header.text;
      }

      const total = this.calculateColumnTotal(header.value);
      return `${header.text} (${total})`;
    },

    calculateColumnTotal(column) {
      if (!this.data || !this.data.length) return 0;

      const sum = this.data.reduce((acc, item) => {
        let value = item[column];

        // Если значение - строка, извлекаем из неё числа
        if (typeof value === 'string') {
          value = parseFloat(value.replace(/[^-0-9.]/g, ''));
        }

        return acc + (isNaN(value) ? 0 : value);
      }, 0);

      // Форматируем число с учётом локали
      return new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }).format(sum);
    },
    clearSelected() {
      this.selected = []
    },
    textFormat(text) {
      if (!text || text == 'NONE' || text == 'undefined') return 'Нет данных'
      return text
    },
    async copy(text) {
      await navigator.clipboard.writeText(text)
    },
    calcMin(sec) {
      let seconds = Math.floor(sec % 60)
      let minutes = Math.floor(sec / 60)
      if (minutes.toString().length == 1) minutes = '0' + minutes
      if (seconds.toString().length == 1) seconds = '0' + seconds
      return `${minutes}:${seconds}`
    },
    itemText(menu_item, item) {
      if (menu_item.action != "stopdelete") return menu_item.title
      else {
        if (item.status == "wait") return "Остановить"
        else return "Удалить"
      }

    },
    itemClass(title) {
      if (title == 'Остановить' || title == 'Удалить' || title == 'Удалить/Остановить') return 'delete_stop'
    },
    numClass(num) {
      if (num[0] == '+') return 'plus'
      else return 'minus'
    },

    enterSelect() {
      if (this.selected.length > 0) {
        let data = {
          hide: false,
          data: this.selected,
          page_type: this.page_type,
          cb_refresh: this.callback_refresh,
          cb_clear: this.clearSelected
        }

        this.$store.commit('notification/setFooterState', data)

      } else {
        let data = {
          hide: true,
          data: [],
          page_type: "none",
          cb_refresh: false,
          cb_clear: false
        }
        console.log(data)
        this.$store.commit('notification/setFooterState', data)
      }
    },
    serverSort() {
      const {sortBy, sortDesc, page} = this.options
      this.callback_refresh({
        page: page,
        sortBy: sortBy,
        sortDesc: sortDesc
      })
    },
    editItem(guid, action, item) {
      if (action == 'draw') this.callback_draw(guid, item)
      if (action == "analytics") this.$router.push('/analytics')
      if (action == 'play') this.callback_play(guid)
      if (action == 'edit') this.callback_edit(true, {id: guid, item: item})
      if (action == 'view') this.callback_view(guid, item.name)
      if (action == 'deletelead') {
        this.$http.post("/base/deletelead", {id: guid}, data => {
          this.callback_refresh()
          return data
        })
      }
      if (action == 'stopdelete') {
        action = "delete"
        if (item.status == "wait") action = "stop"
      }
      if (action == 'stop') this.callback_stop(guid)
      if (action == 'delete') this.callback_delete(guid)
      if (action == 'user_view') this.callback_view(item.phone)
      if (action == 'pays_success') this.callback_edit(item.phone, item.id_number, "success")
      if (action == 'pays_canselled') this.callback_edit(item.phone, item.id_number, "cancelled")
      if (action == 'delete_black') this.callback_edit(item.phone)
    },
  },
  computed: {
    tableData() {
      // Получаем обычные данные для текущей страницы
      const start = (this.page - 1) * this.localPerPage;
      const end = start + this.localPerPage - 2; // Вычитаем 2 для итоговых строк
      const paginatedData = this.data.slice(start, end);

      // Добавляем итоговые строки к пагинированным данным
      return [
        ...paginatedData,
        this.calculateTotalRow(this.data), // Считаем итоги по ВСЕМ данным
        this.calculateAverageRow(this.data) // Считаем среднее по ВСЕМ данным
      ];
    },

    // Обновляем подсчет количества страниц
    pageCount() {
      return Math.ceil((this.data.length) / (this.localPerPage - 2)); // Вычитаем 2 для учета итоговых строк
    },
    paginatedDataWithTotal() {
      return [this.calculateTotalRow(this.data), this.calculateAverageRow(this.data)];
    },
    processedHeaders() {
      return this.headers.map(header => ({
        ...header,
        text: this.getHeaderWithTotal(header)
      }));
    },
  }
}
</script>
<style lang="scss">
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: #666;
    font-size: 14px;
  }

  .per-page-select {
    width: 80px;
  }
}

.v-select.per-page-select {
  .v-input__control {
    min-height: 32px;
  }

  .v-input__slot {
    min-height: 32px;
  }
}


.record {
  width: 100%;
  text-align: center;
}

.table_text {
  max-width: 300px;
  width: 100%;
}

.operator_ico {
  display: flex;
  align-items: center;
}

.operator_ico > .icon {
  margin-right: 5px;
}

.hidden {
  display: none;
}

.table_text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}

.num {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
}

.plus {
  color: #008E7D;
}

.delete_stop {
  color: #EE505A;
}

.delete_stop:hover {
  cursor: pointer;
}

.minus {
  color: #C74952;
}

.time {
  margin-right: 8px;
}

.input_wrap {
  width: 132px;
  height: 32px;
}

.props:hover {
  background: #E6F4FC;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.hover_actions:hover {
  cursor: pointer;
}

.props {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.auto {
  display: inline-block;
  background: #E1E0FE;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 6px;
}

.status_wait {
  background: #dfe76b;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_stop,
.status_uncall {
  text-align: center;
  background: #FEE0E0;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_end,
.status_success {
  background: #CFF1E9;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.delete {
  font-size: 15px;
  color: #EE505A;
  cursor: pointer;
}

.v-list div {
  cursor: pointer;
}

.table {
  background: #fff;
  border-radius: 8px;
  padding: 20px 16px 32px 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.v-application .elevation-1 {
  box-shadow: none !important;;
}

.target_container {
  display: flex;
  align-items: flex-start;
  justify-content: left;
}

.target_des {
  display: inline-block;
}

.target {
  width: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.v-data-table tfoot td {
  font-weight: bold;
  background-color: #f5f5f5;
  border-top: 2px solid #e0e0e0;
}

</style>