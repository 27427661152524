import Vue from 'vue'
import VueRouter from 'vue-router'
import Bases from '../views/BasesView'
import Dashboard from '../views/DashboardView'
import Detail from '../views/DetailView'
import Support from '../views/SupportView'
import Documents from '../views/DocumentsView'
import Reference from '../views/ReferenceView'
import Rates from '../views/RatesView'
import Tags from '../views/TagsView'
import Templates from '../views/TemplatesView'
import Help from '../views/HelpView'
import Cabinet from '../views/CabinetView'
import Channels from '../views/ChannelsView'
import UserSettings from '../views/UserSettings'
import Users from '../views/admin/UsersAdminView'
import Senders from '../views/SendersView'
import BlackList from '../views/BlacklistView'
import BlackListAdmin from '../views/admin/BlackListAdminView'
import Report from "@/views/ReportView"
import OneReportView from "@/views/OneReportView.vue";

import Alphas from "@/views/AlphasView"
import AlphasAdminView from "@/views/admin/AlphasAdminView.vue";
import TemplatesAdminView from "@/views/admin/TemplatesAdminView.vue";
import PriceAdminView from "@/views/admin/PriceAdminView.vue";
import UniquePricesView from "@/views/admin/UniquePricesView.vue";
import UserStatistic from "@/views/admin/UserStatistic.vue";
import SystemPrices from "@/views/admin/SystemPrices.vue";
import BalancerSetupsView from "@/views/admin/BalancerSetupsView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/bases',
    name: 'bases',
    component: Bases
  },
  {
    path: '/bases/:id',
    name: 'base',
    component: Bases
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
  // {
  //   path: '/vitrines',
  //   name: 'vitrines',
  //   component: Vitrines
  // },
  {
    path: '/rates',
    name: 'rates',
    component: Rates
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/reports',
    name: 'reports',
    component: Report
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tags
  },
  {
    path: '/alphas',
    name: 'alphas',
    component: Alphas
  },
  {
    path: '/reference',
    name: 'reference',
    component: Reference
  },
  {
    path: '/templates',
    name: 'templates',
    component: Templates
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  },
  {
    path: '/account',
    name: 'cabinet',
    component: Cabinet
  },
  {
    path: '/user_settings',
    name: 'user_settings',
    component: UserSettings
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  // {
  //   path: '/users_payments',
  //   name: 'users_pays',
  //   component: UsersPays
  // },
  {
    path: '/senders',
    name: 'senders',
    component: Senders
  },
  {
    path: '/blacklist-admin',
    name: 'blacklist',
    component: BlackListAdmin
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    component: BlackList
  },
  // {
  //   path: '/extra_services',
  //   name: 'extra_services',
  //   component: ExtraServices
  // },
	{
		path: '/bases',
		name: 'bases',
		component: Bases
	},
	{
		path: '/bases/:id',
		name: 'base',
		component: Bases
	},
	{
		path: '/',
		name: 'dashboard',
		component: Dashboard
	},
	{
		path: '/documents',
		name: 'documents',
		component: Documents
	},
	{
		path: '/support',
		name: 'support',
		component: Support
	},
	{
		path: '/rates',
		name: 'rates',
		component: Rates
	},
	{
		path: '/detail',
		name: 'detail',
		component: Detail
	},
	{
		path: '/reports',
		name: 'reports',
		component: Report
	},
	{
		path: '/report/:id',
		name: 'one-report',
		component: OneReportView
	},
	{
		path: '/tags',
		name: 'tags',
		component: Tags
	},
	{
		path: '/alphas',
		name: 'alphas',
		component: Alphas
	},
	{
		path: '/reference',
		name: 'reference',
		component: Reference
	},
	{
		path: '/system-prices',
		name: 'system-prices',
		component: SystemPrices
	},
	{
		path: '/balancer_setups',
		name: 'balancer_setups-prices',
		component: BalancerSetupsView
	},
	{
		path: '/channels',
		name: 'channels',
		component: Channels
	},
	{
		path: '/templates',
		name: 'templates',
		component: Templates
	},
	{
		path: '/help',
		name: 'help',
		component: Help
	},
	{
		path: '/account',
		name: 'cabinet',
		component: Cabinet
	},
	{
		path: '/user_settings',
		name: 'user_settings',
		component: UserSettings
	},
	{
		path: '/users',
		name: 'users',
		component: Users
	},
	// {
	// 	path: '/users_payments',
	// 	name: 'users_pays',
	// 	component: UsersPays
	// },
	{
		path: '/senders',
		name: 'senders',
		component: Senders
	},
	// {
	// 	path: '/extra_services',
	// 	name: 'extra_services',
	// 	component: ExtraServices
	// },
	// {
	// 	path: '/tariff_minutes',
	// 	name: 'tariff_minutes',
	// 	component: TariffsMinutes
	// },


	{
		path: '/alphas-admin',
		name: 'alphas-admin',
		component: AlphasAdminView
	},
	{
		path: '/templates-admin',
		name: 'templates-admin',
		component: TemplatesAdminView
	},
	{
		path: '/price-admin',
		name: 'price-admin',
		component: PriceAdminView
	},
	{
		path: '/unique-prices',
		name: 'unique-prices',
		component: UniquePricesView
	},
	{
		path: '/user_statistic',
		name: 'user_statistic',
		component: UserStatistic
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router