<template>
  <DefaultView title-page="Системные цены">

    <v-expansion-panels>
      <v-expansion-panel
          v-for="(data,i) in table.data"
          :key="i"
      >
        <v-expansion-panel-header>
          <div style="display: flex; flex-direction: row; gap: 10px; justify-content: flex-start; align-items: center;">
            <div
                style="width: 10px; height: 10px; border-radius: 100%"
                :style="'background: '+ ( data.channel_info.status === 'working' ? 'green' : 'red') + ';'"></div>
            <b>{{ data.channel_info.name }}</b> - <i><u>{{ data.channel_info.description }}</u></i>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start">
            <div><b>Системный ID</b> - <i><u>{{ data.channel_info.id }}</u></i></div>
            <div><b>Системное название</b> - <i><u>{{ data.channel_info.system_name }}</u></i></div>
            <div><b>Дата добавления</b> - <i><u>{{ data.channel_info.addition_date }}</u></i></div>
            <div><b>Разрешенный трафик</b> - <i><u>{{ data.channel_info.accept_traffic }}</u></i></div>
            <div><b>Статус</b> - <i><u>{{ data.channel_info.status }}</u></i></div>
            <div><b>Описание статуса</b> - <i><u>{{ data.channel_info.status_description }}</u></i></div>
          </div>
          <v-expansion-panels style="margin-top: 20px">
            <v-expansion-panel
                v-for="(prices,i) in (data.prices)"
                :key="i"
            >
              <v-expansion-panel-header>
                <div><b>{{ prices.sms_type.name }}</b></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BigButton text-btn="Добавить ценник" icon-btn="plus"
                           @click="openAddPriceModal(data.channel_info.id, data.channel_info.name, prices.sms_type.id)"/>

                <BaseTable :loading="loading" :data="prices.operators" :headers="table.headers">
                  <template v-slot:action="item">
                    <v-list-item @click="openEditPriceModal(data.channel_info.id, data.channel_info.name, item)">
                      <v-list-item-title>
                        Изменить
                      </v-list-item-title>
                    </v-list-item>
                    <PopconfirmButton @yes="requestDeletePrice(item.item, prices)">
                      <v-list-item>
                        <v-list-item-title>
                          Удалить
                        </v-list-item-title>
                      </v-list-item>
                    </PopconfirmButton>
                  </template>
                </BaseTable>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div><b>Подключенные альфы</b></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <BigButton text-btn="Подключить альфу" icon-btn="plus"
                           @click="openAddAlphaModal(data.channel_info.id, data.channel_info.name, data.alphas)"/>

                <BaseTable :loading="loading" :data="data.alphas || []" :headers="table.alpha_headers">
                  <template v-slot:action="item">
                    <v-list-item @click="disableAlphaFromChannel(item.item, data.channel_info.name)">
                      <v-list-item-title>
                        Отключить от канала
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </BaseTable>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-pagination v-if="table.data.length > 10" :length="Math.ceil(table.data.length / 10)" v-model="pagination_number"
                  :color="'#60c880'"></v-pagination>
    <BaseModal
        :show-modal="popup.add_price"
        @close="closePopup"
        @clickBtn="valuePopupPrice.is_edit ? requestUpdatePrice() : requestAddNewPrice()"
        :title="valuePopupPrice.is_edit ? 'Редактировать цену' : 'Добавить цену'"
    >
      <WrapperInputs title-inputs="Выбранный канал">
        <v-select
            v-model="valuePopupPrice.channelName"
            :items="[valuePopupPrice.channelName]"
            disabled
            height="44"
        >{{ valuePopupPrice.channelName }}
        </v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Оператор">
        <v-select
            v-model="valuePopupPrice.operator"
            :items="valuePopupPrice.is_edit ? [valuePopupPrice.operator] : operators"
            :disabled="valuePopupPrice.is_edit"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Цена">
        <v-text-field
            v-model="valuePopupPrice.new_price"
            placeholder="Цена"
            type="number"
        />
      </WrapperInputs>
    </BaseModal>
    <BaseModal
        :show-modal="popup.add_alpha"
        @close="closePopup"
        @clickBtn="newAlphaToChannelConnect()"
        title="Подключить альфу"
    >
      <WrapperInputs title-inputs="Выбранный канал">
        <v-select
            v-model="valuePopupAlpha.channelName"
            :items="[valuePopupAlpha.channelName]"
            disabled
            height="44"
        >{{ valuePopupAlpha.channelName }}
        </v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Список имен отправителей">
        <v-select
            v-model="valuePopupAlpha.selected_alpha"
            :items="filteredAlphaNames"
            item-text="name"
            return-object
            height="44"
            placeholder="Выбрать доступное имя отправителя"
        ></v-select>
      </WrapperInputs>
      <!--      <WrapperInputs title-inputs="Цена">-->
      <!--        <v-text-field-->
      <!--            v-model="valuePopupPrice.new_price"-->
      <!--            placeholder="Цена"-->
      <!--            type="number"-->
      <!--        />-->
      <!--      </WrapperInputs>-->
    </BaseModal>
  </DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import BaseTable from "@/components/UI/BaseTable.vue";
import BaseModal from "@/components/UI/BaseModal.vue";
import WrapperInputs from "@/components/UI/WrapperInputs.vue";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import BigButton from "@/components/UI/BigButton.vue"
import axios from "axios";
import PopconfirmButton from "@/components/UI/PopconfirmButton.vue";

export default {
  name: "UniquePricesView",
  components: {PopconfirmButton, WrapperInputs, BigButton, BaseModal, BaseTable, DefaultView},
  mixins: [formattingPhoneMixin],
  data() {
    return {
      table: {
        data: [],
        filteredData: [],
        headers: [
          {text: 'Оператор', value: 'operator_name', sortable: true},
          {text: 'Цена', value: 'price', sortable: true},
          {text: 'Действия', value: 'action_menu', sortable: false, width: 200}
        ],
        alpha_headers: [
          {text: 'Название', value: 'alpha_name', sortable: true},
          {text: 'Действия', value: 'action_menu', sortable: false, width: 200}
        ]
      },
      loading: true,
      popup: {
        add_price: false,
        add_alpha: false
      },
      optionsSelectStatus: [
        {value: 'changePrice', label: 'Редактировать'},
      ],
      operators: [],
      alphas_list: [],
      valuePopupPrice: {
        id: null,
        channelId: null,
        channelName: '',
        operator: '',
        new_price: null,
        is_edit: null,
        sms_type_id: null
      },
      valuePopupAlpha: {
        channel_id: null,
        channelName: '',
        alphas_exist: [],
        selected_alpha: null
      },
      userList: [],
      lastFilterNumber: '',
      pagination_number: 1
    }
  },
  methods: {
    async getOperatorsList() {
      await this.$http.get(`${process.env.VUE_APP_CUCKOO_API}/list/operator`, result => {
            this.operators = result
          }
      )
    },

    async getAlphaList() {
      await this.$http.get(`${process.env.VUE_APP_BALANCER_API}/alphas/list`, result => {
        if (result.length === 0) {
          this.alphas_list = []
        } else {
          this.alphas_list = result.filter(item => item.status_id === 1)
        }
      })
    },

    async openAddPriceModal(channelId, channelName, sms_type_id) {
      if (this.operators.length < 1) {
        await this.getOperatorsList();
      }
      this.valuePopupPrice.is_edit = false
      this.valuePopupPrice.channelId = channelId;
      this.valuePopupPrice.channelName = channelName;
      this.valuePopupPrice.sms_type_id = sms_type_id
      this.popup.add_price = true;
    },

    async openEditPriceModal(channelId, channelName, item) {
      if (this.operators.length < 1) {
        await this.getOperatorsList();
      }
      this.valuePopupPrice.is_edit = true
      this.valuePopupPrice.channelId = channelId;
      this.valuePopupPrice.operator = item.item.operator_name
      this.valuePopupPrice.id = item.item.price_id
      this.valuePopupPrice.new_price = item.item.price
      this.valuePopupPrice.channelName = channelName;
      this.popup.add_price = true;
    },

    async requestUpdatePrice() {
      this.loading = true
      try {
        const response = await axios.patch(`${process.env.VUE_APP_BANK_API}/price/system`, {
          new_price: Number(this.valuePopupPrice.new_price),
          price_id: Number(this.valuePopupPrice.id)
        }, {timeout: 20 * 1000})

        if (response.status === 202) {
          this.render()
          this.popup.add_price = false;
          this.$toast.add({severity: 'success', summary: `Цена обновлена!`, life: 3000});
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: `Ошибка - ${error.response.data.error_code}`,
          detail: error.response.data.error_message,
          life: 3000
        });
      }
    },

    async requestAddNewPrice() {
      this.loading = true
      try {
        const response = await axios.post(`${process.env.VUE_APP_BANK_API}/price/system`, {
          coast: Number(this.valuePopupPrice.new_price),
          channel_id: this.valuePopupPrice.channelId,
          operator: this.valuePopupPrice.operator,
          sms_type_id: Number(this.valuePopupPrice.sms_type_id)
        }, {timeout: 20 * 1000})

        if (response.status === 202) {
          this.render()
          this.popup.add_price = false;
          this.$toast.add({severity: 'success', summary: `Цена добавлена!`, life: 3000});
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: `Ошибка - ${error.response.data.error_code}`,
          detail: error.response.data.error_message,
          life: 3000
        });
      }
      this.loading = false
    },

    async requestDeletePrice(item, prices_count) {
      if (item.operator_name === "Прочие") {
        this.$toast.add({
          severity: 'error',
          summary: `Эту цену удалять нельзя!`,
          detail: 'Ее можно только редактировать)',
          life: 3000
        });
      } else if (prices_count < 2) {
        this.$toast.add({
          severity: 'error',
          summary: `Нельзя удалять единственное значение!`,
          detail: 'Сперва добавь другие значения!',
          life: 3000
        });
      } else {
        this.loading = true
        try {
          const response = await axios.delete(`${process.env.VUE_APP_BANK_API}/price/system?price_id=${item.price_id}`, {timeout: 20 * 1000})
          if (response.status === 202) {
            this.render()
            this.$toast.add({severity: 'success', summary: `Цена удалена!`, life: 3000});
          }
        } catch (error) {
          this.$toast.add({
            severity: 'error',
            summary: `Ошибка - ${error.response.data.error_code}`,
            detail: error.response.data.error_message,
            life: 3000
          });
        }
        this.loading = false
      }
    },

    showConfirm(message, header) {
      return new Promise((resolve) => {
        this.$confirm.require({
          message,
          header,
          acceptLabel: "Да",
          rejectLabel: "Нет",
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      });
    },

    async disableAlphaFromChannel(item, channel_name) {
      const confirmations = [
        {message: 'Вы хотите отключить данное имя отправителя от канала?', header: 'Вы уверены?'},
        {message: 'Это необратимый процесс!', header: 'Точно?'},
        // {message: 'Последнее предупреждение!', header: 'Действительно уверены?'},
        // {message: 'Осталось немного. Вы уверены?', header: 'Подтверждаете действие?'},
        // {message: 'Действие серьезное. Вы осознаете последствия?', header: 'Еще раз убедимся'},
        // {message: 'Вы точно понимаете, что это нельзя отменить?', header: 'Последняя проверка'},
        // {message: 'Всё верно? Все данные будут потеряны.', header: 'Критическое действие'},
        // {message: 'Убедитесь, что вы хотите продолжить.', header: 'Еще один шаг'},
        // {message: 'Это окончательное подтверждение.', header: 'Вы точно уверены?'},
        // {message: 'Это последний шаг. Выполняем действие?', header: 'Действие необратимо'},
      ];

      for (const confirm of confirmations) {
        const result = await this.showConfirm(confirm.message, confirm.header);
        if (!result) {
          console.log('Действие отменено');
          this.$toast.add({severity: 'info', summary: `Действие отменено`, life: 3000});
          return;
        }
      }

      this.loading = true
      try {
        const response = await axios.delete(`${process.env.VUE_APP_BALANCER_API}/alphas/connect?id=${item.connection_id}`, {timeout: 20 * 1000})
        if (response.status === 204) {
          this.render()
          this.$toast.add({
            severity: 'info',
            summary: `Альфа ${item.alpha_name} была успешно отключена от канала - ${channel_name}`,
            life: 8000
          });
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: `Ошибка - ${error.response.data.error_code}`,
          detail: error.response.data.error_message,
          life: 3000
        });
      }
      this.loading = false
    },

    async newAlphaToChannelConnect() {
      this.loading = true
      try {
        const response = await axios.post(`${process.env.VUE_APP_BALANCER_API}/alphas/connect`, {
          alpha_id: this.valuePopupAlpha.selected_alpha.id,
          channel_id: this.valuePopupAlpha.channel_id
        }, {timeout: 20 * 1000})
        if (response.status === 201) {
          this.closePopup()
          this.render()
          this.$toast.add({
            severity: 'success',
            summary: `Альфа была успешно подключена к каналу!`,
            life: 8000
          });
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: `Ошибка - ${error.response.data.error_code}`,
          detail: error.response.data.error_message,
          life: 3000
        });
      }
      this.loading = false
    },

    async openAddAlphaModal(channel_id, channel_name, alphas_exist) {
      if (!alphas_exist) {
        alphas_exist = []
      }
      if (this.alphas_list.length < 1) {
        await this.getAlphaList()
      }
      this.valuePopupAlpha.channel_id = channel_id;
      this.valuePopupAlpha.channelName = channel_name;
      this.valuePopupAlpha.alphas_exist = alphas_exist.map(item => item.name);
      this.popup.add_alpha = true
    },


    render() {
      this.$http.get(`${process.env.VUE_APP_BALANCER_API}/channels/with_prices_and_alphas`, result => {
        this.table.data = result
        this.loading = false
      })
    },

    closePopup() {
      this.popup.add_price = false
      this.popup.add_alpha = false
      this.valuePopupPrice = {
        _id: null,
        userId: '',
        operator: '',
        price: null,
        limit: null,
      }
      this.valuePopupAlpha = {
        channel_id: null,
        channelName: '',
        alphas_exist: [],
        selected_alpha: null
      }
    },
  },

  mounted() {
    this.render()
  },

  computed: {
    filteredAlphaNames() {
      return this.alphas_list
          .filter((list_item) => !this.valuePopupAlpha.alphas_exist.includes(list_item.name))
    },
  }
}
</script>

<style scoped>
.v-expansion-panels {
  gap: 10px;
}
</style>