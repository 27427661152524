<template>
  <div class="render">
    <h1>Dashboard</h1>

    <div class="flex">
      <div class="large block">
        <div class="delivered">
          <div class="header">
            <h3>
              Общая доставка
            </h3>
          </div>
          <LineChart />
        </div>
        <div class="senders">
          <div class="header">
            <h3>По рассылкам</h3>
            <v-select
                class="select"
                :items="senders"
                label="Выберите рассылку"
                dense
                solo
            ></v-select>
          </div>
          <Bar />
        </div>
        <div class="flex pies">
          <div class="operators">
            <div class="header">
              <h3>По операторам</h3>
              <Pie :labels="['MTS', 'Tele2', 'Beeline', 'Megafon']" />
            </div>
          </div>
          <div class="alphas">
            <div class="header">
              <h3>По именам</h3>
              <Pie :labels="['moe-imya.ru', 'soseda-imya', '5ka', 'cyriulnya']" />
            </div>
          </div>
        </div>
      </div>
      <div class="small block">
        <div class="calendar">
          <h2 class="aftertitle">
            Выберите даты
          </h2>
          <DateRange :model-value="dates" />
        </div>
        <div class="short_stat">
          <div class="block mini">
            Отправлено:
            <span>47 200</span>
          </div>
          <div class="block mini">
            Доставлено:
            <span>45 620</span>
          </div>
          <div class="block mini">
            Не доставлено:
            <span>1 458</span>
          </div>
          <div class="block mini">
            Доставка:
            <span>99.8%</span>
          </div>
        </div>
        <div class="balance_up">
          <div class="header">
            <h3>Пополнения</h3>
            <ul class="list">
              <li>
                <div class="sum">+100 000р</div>
                <div class="date">14.01.22</div>
              </li>
              <li>
                <div class="sum">+700 000р</div>
                <div class="date">15.01.22</div>
              </li>
              <li>
                <div class="sum">+10 000р</div>
                <div class="date">18.01.22</div>
              </li>
              <li>
                <div class="sum">+1 000 000р</div>
                <div class="date">21.01.22</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">

import Bar from '../components/charts/Bar.vue'
import LineChart from '../components/charts/Line.vue'
import Pie from '../components/charts/Pie.vue'
import DateRange from '../components/Filter/DateRange.vue'
export default {
  components: {
    Bar,
    LineChart,
    Pie,
    DateRange
  },
  data: () => ({
    dates: [],
    senders: [134345,26453,3342]
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' - ')
    },
  },
}

</script>
<style lang="sass" scoped>

h1
  margin-bottom: 30px
  font-size: 32px
.balance_up
  background: #dfdbef
  border-radius: 15px
  padding: 30px
  .list
    li
      padding: 10px
      display: flex
      list-style: none
      justify-content: space-between
      .sum
        color: green
        font-size: 18px
        margin-bottom: 0
        font-weight: 900
      .date
        margin-bottom: 0
        color: #898787
.header
  display: flex
  flex-direction: column
  position: relative
.block .header
  margin-bottom: 0
.select
  position: absolute
  top: 0
  right: 0
.block
  border-radius: 15px
  padding: 20px
  div
    margin-bottom: 30px

.mini
  background: #fed500
  margin-bottom: 20px
  &:last-child
    margin-bottom: 0
  span
    font-size: 24px

.large
  width: 70%
  background: #fff

.small
  width: 25%
  margin-left: 5%
  background: #fff

.aftertitle
  margin-bottom: 20px

.short_stat
  padding: 30px
  background: #dfdbef
  border-radius: 15px

.operators, .alphas
  width: 50%

</style>