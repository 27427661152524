import { render, staticRenderFns } from "./BalancerSetupsView.vue?vue&type=template&id=b771a94a&scoped=true&"
import script from "./BalancerSetupsView.vue?vue&type=script&lang=js&"
export * from "./BalancerSetupsView.vue?vue&type=script&lang=js&"
import style0 from "./BalancerSetupsView.vue?vue&type=style&index=0&id=b771a94a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b771a94a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VListItem,VListItemTitle,VSelect,VSwitch,VTextField})
