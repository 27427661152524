var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultView',{attrs:{"title-page":"Альфы"}},[_c('FilterCustom',{attrs:{"visible":{phone:true,maska:'+7 ### ###-##-##'}},on:{"updateFilter":_vm.onUpdateFilter}}),_c('BaseTable',{attrs:{"loading":_vm.loading,"headers":_vm.table.headers,"data":_vm.table.filterData},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('SelectTable',{attrs:{"value":item.status,"options":_vm.optionsSelectStatus},on:{"update":function (val) { return _vm.onUpdate(val, item); }}})]}},{key:"document",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"value":item.documents,"href":item.documents,"target":"_blank","icon":"","small":"","color":"indigo"}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}},{key:"operators",fn:function(ref){
var item = ref.item;
return _vm._l((item.operators),function(val){return _c('div',{key:val},[(val == 'MTS')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_mts"}}),_vm._v(" MTS ")],1):_vm._e(),(val == 'Beeline')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_beeline"}}),_vm._v(" Beeline ")],1):_vm._e(),(val == 'Megafon')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_megafon"}}),_vm._v(" Megafon ")],1):_vm._e(),(val == 'Tele2')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_tele"}}),_vm._v(" Tele2 ")],1):_vm._e(),(val == 'Tinkof')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_tinkoff"}}),_vm._v(" Tinkof ")],1):_vm._e(),(val == 'Yota')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_yota"}}),_vm._v(" Yota ")],1):_vm._e()])})}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.price},on:{"update:returnValue":function($event){return _vm.$set(item, "price", $event)},"update:return-value":function($event){return _vm.$set(item, "price", $event)},"save":function($event){return _vm.savePrice(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Редактировать","single-line":""},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.price)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }