import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import {store} from './store'
import {http} from './axios/HTTP'
import Maska from 'maska'
import 'primevue/resources/themes/tailwind-light/theme.css'; // Тема PrimeVue
import 'primevue/resources/primevue.min.css'; // Основные стили PrimeVue
import 'primeicons/primeicons.css'; // Иконки PrimeIcons

Vue.prototype.$http = new http(store)
Vue.use(ConfirmationService);
Vue.use(PrimeVue)
Vue.use(ToastService)
Vue.use(Maska)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store
}).$mount('#app')