<template>
	<div class="menu_wrapper">

		<v-menu
				bottom
				open-on-hover
				offset-y
				v-for="(itemA, keyA) in menu" :key="keyA"
		>
			<template v-if="role === itemA.role" v-slot:activator="{ on, attrs }">
				<v-btn
						text
						class="parent"
						v-bind="attrs"
						v-on="on"
				>
					{{ itemA.title }}
					<Icon name="dropdown"/>
				</v-btn>
			</template>

			<v-list v-if="role === itemA.role">
				<v-list-item
						class="parent_item"
						v-for="(itemB, index) in itemA.inner"
						:key="index"
						@click="$router.push(itemB.namePath)"
				>
					<v-list-item-title>{{ itemB.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import Icon from '../components/SetIcon.vue'

export default {
	name: "TopMenu",
	components: {
		Icon
	},
	data() {
		return {
			menu: {
				senders: {
					title: 'Рассылки',
					role: 'user',
					inner: [
						{title: 'Рассылки', namePath: 'senders'},
						{title: 'Имена отправителей', namePath: 'alphas'},
						// {title: 'Сервисные шаблоны', namePath: 'templates'}
					],
				},
				bases: {
					title: 'Базы',
					role: 'user',
					inner: [
						{title: 'Базы', namePath: 'bases'},
						{title: 'Тэги', namePath: 'tags'},
						{title: 'Черный список', namePath: 'blacklist'},
					],
				},
				stat: {
					title: 'Статистика',
					role: 'user',
					inner: [
						{title: 'Детализация', namePath: 'detail'},
						{title: 'Отчеты', namePath: 'reports'}
					],
				},
				controlAdmin: {
					title: 'Управление',
					role: 'manager',
					inner: [
						{title: 'Юзеры', namePath: 'users'},
						{title: 'Альфы', namePath: 'alphas-admin'},
						// {title: 'Каналы', namePath: 'channels'},
						// {title: 'Шаблоны', namePath: 'templates-admin'},
						{title: 'Цены', namePath: 'price-admin'},
						{title: 'Уникальные цены', namePath: 'unique-prices'},
						{title: 'Системные цены', namePath: 'system-prices'},
						// {title: 'Установки балансировщика', namePath: 'balancer_setups'},
						{title: 'Черный список', namePath: 'blacklist'}
					],
				},
				reportsAdmin: {
					title: 'Отчеты',
					role: 'manager',
					inner: [
						{title: 'Общий', namePath: 'reports'},
					],
				}
			}
		}
	},
	computed: {
		role() {
			return this.$store.getters['user/getRole']
		}
	},
	methods: {}
}
</script>

<style lang="sass" scoped>
.parent
  text-transform: capitalize

.parent_item
  text-align: left

.icon
  margin-left: 10px
</style>