import { render, staticRenderFns } from "./WrapperInputs.vue?vue&type=template&id=1d251734&scoped=true&"
import script from "./WrapperInputs.vue?vue&type=script&lang=js&"
export * from "./WrapperInputs.vue?vue&type=script&lang=js&"
import style0 from "./WrapperInputs.vue?vue&type=style&index=0&id=1d251734&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d251734",
  null
  
)

export default component.exports