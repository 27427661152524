<template>
  <DefaultView title-page="Ручные установки балансировщика">
    <template v-slot:right-title>
      <BigButton text-btn="Добавить установку" @click="openCreateSetup()"/>
    </template>

    <BaseTable :loading="loading" :data="table.data" :headers="table.headers">
      <template v-slot:action="item">
        <v-list-item @click="openEditSetup(item)">
          <v-list-item-title>
            Изменить
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openEditSetup(item)">
          <v-list-item-title>
            Вкл./Выкл.
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="openEditSetup(item)">
          <v-list-item-title>
            Удалить
          </v-list-item-title>
        </v-list-item>
      </template>
    </BaseTable>

    <BaseModal
        :show-modal="popup.setup"
        @close="closePopup"
        @clickBtn="saveUpdate()"
        :title="edit_setup.edit_mode ? 'Редактирование установки' : 'Создание установки'">
      <v-switch
          :model-value="edit_setup.permanent"
          :input-value="edit_setup.permanent"
          color="#FEE600"
          label="Перманентная"
      ></v-switch>
      <v-switch
          :model-value="edit_setup.active"
          :input-value="edit_setup.active"
          color="#FEE600"
          label="Активна"
      ></v-switch>
      <WrapperInputs title-inputs="Название">
        <v-text-field
            v-model="edit_setup.setup_name"
        >{{edit_setup.name}}</v-text-field>
      </WrapperInputs>
      <WrapperInputs title-inputs="Тип трафика">
        <v-select
            v-model="edit_setup.traffic_type"
            :items="[{id: 1, name: 'Рекламный'},{id: 2, name: 'Сервисный'}]"
            item-text="name"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Альфа">
        <v-select
            v-model="edit_setup.traffic_type"
            :items="[{id: 1, name: 'Рекламный'},{id: 2, name: 'Сервисный'}]"
            item-text="name"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Канал">
        <v-select
            v-model="edit_setup.traffic_type"
            :items="[{id: 1, name: 'Рекламный'},{id: 2, name: 'Сервисный'}]"
            item-text="name"
            height="44"
        ></v-select>
      </WrapperInputs>

      <WrapperInputs title-inputs="Принадлежит пользователю">
        <v-select
            v-model="edit_setup.old_user_id"
            :items="[{user_old_id: 'asfsafsadf', user_phone: 'Рекламный'}, {user_old_id: 'asfsafsadf', user_phone: 'Админ'}]"
            item-text="user_phone"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Работает с">
        <v-select
            v-model="edit_setup.day_start_activity"
            :items="[null, ...Array.from({ length: 31 }, (_, i) => i + 1)]"
            item-text="name"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Работает до">
        <v-select
            v-model="edit_setup.day_end_activity"
            :items="[null, ...Array.from({ length: 31 }, (_, i) => i + 1).filter(day => day > edit_setup.day_start_activity)]"
            item-text="name"
            height="44"
        ></v-select>
      </WrapperInputs>
      <WrapperInputs title-inputs="Описание">
        <v-text-field
            hide-details="auto"
            v-model="edit_setup.description"
        ></v-text-field>
      </WrapperInputs>
    </BaseModal>
  </DefaultView>
</template>

<script>
import DefaultView from "@/components/ContainersView/DefaultView.vue";
import BaseModal from "@/components/UI/BaseModal.vue";
import WrapperInputs from "@/components/UI/WrapperInputs.vue";
import formattingPhoneMixin from "@/mixins/formattingPhoneMixin";
import BigButton from "@/components/UI/BigButton.vue"
import axios from "axios";
import BaseTable from "@/components/UI/BaseTable.vue";

export default {
  name: "BalancerSetupsView",
  components: {BaseTable, WrapperInputs, BigButton, BaseModal, DefaultView},
  mixins: [formattingPhoneMixin],
  data() {
    return {
      table: {
        data: [],
        filteredData: [],
        headers: [
          // {text: 'Действия', value: 'action_menu'},
          {text: 'ID', value: 'id'},
          {text: 'Активна', value: 'active'},
          {text: 'Название', value: 'setup_name'},
          {text: 'Перманентная', value: 'permanent'},
          {text: 'Трафик', value: 'traffic_type'},
          {text: 'Альфа', value: 'alpha_id'},
          {text: 'Канал', value: 'channel_id'},
          {text: 'Принадлежит пользователю', value: 'old_user_id'},
          {text: 'Тип операции', value: 'operation_type'},
          {text: 'start_date', value: 'start_date'},
          {text: 'end_date', value: 'end_date'},
          {text: 'day_start_activity', value: 'day_start_activity'},
          {text: 'day_end_activity', value: 'day_end_activity'},
          {text: 'Описание', value: 'description'},
        ],
      },
      loading: true,
      popup: {
        setup: false,
      },
      operators: [],
      alphas_list: [],
      channels_list: [],
      edit_setup: {
        edit_mode: false,
        id: null,
        active: null,
        name: '',
        permanent: null,
        traffic_type: null,
        old_user_id: null,
        operation_type: null,
        start_date: null,
        end_date: null,
        day_start_activity: null,
        day_end_activity: null,
        description: null,
      },
      userList: [],
    }
  },
  methods: {
    async getOperatorsList() {
      await this.$http.get(`${process.env.VUE_APP_CUCKOO_API}/list/operator`, result => {
            this.operators = result
          }
      )
    },

    async getAlphaList() {
      await this.$http.get(`${process.env.VUE_APP_BALANCER_API}/alphas/list`, result => {
        if (result.length === 0) {
          this.alphas_list = []
        } else {
          this.alphas_list = result.filter(item => item.status_id === 1)
        }
      })
    },

    async getOldUserData() {

    },

    async saveUpdate() {
      console.log(123123)
    },

    async getChannelsList() {
      await this.$http.get(`${process.env.VUE_APP_BALANCER_API}/channels`, result => {
        this.channels_list = result
      })
    },

    async openEditSetup(item) {
      if (this.alphas_list.length < 1) {
        await this.getAlphaList();
      }
      this.edit_setup = item.item
      this.edit_setup.edit_mode = true
      console.log(this.edit_setup)
      this.popup.setup = true;
    },

    async openCreateSetup() {
      if (this.alphas_list.length < 1) {
        await this.getAlphaList();
      }
      this.edit_setup.edit_mode = false
      this.popup.setup = true;
    },

    showConfirm(message, header) {
      return new Promise((resolve) => {
        this.$confirm.require({
          message,
          header,
          acceptLabel: "Да",
          rejectLabel: "Нет",
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      });
    },

    async disableAlphaFromChannel(item, channel_name) {
      const confirmations = [
        {message: 'Вы хотите отключить данное имя отправителя от канала?', header: 'Вы уверены?'},
        {message: 'Это необратимый процесс!', header: 'Точно?'},
        // {message: 'Последнее предупреждение!', header: 'Действительно уверены?'},
        // {message: 'Осталось немного. Вы уверены?', header: 'Подтверждаете действие?'},
        // {message: 'Действие серьезное. Вы осознаете последствия?', header: 'Еще раз убедимся'},
        // {message: 'Вы точно понимаете, что это нельзя отменить?', header: 'Последняя проверка'},
        // {message: 'Всё верно? Все данные будут потеряны.', header: 'Критическое действие'},
        // {message: 'Убедитесь, что вы хотите продолжить.', header: 'Еще один шаг'},
        // {message: 'Это окончательное подтверждение.', header: 'Вы точно уверены?'},
        // {message: 'Это последний шаг. Выполняем действие?', header: 'Действие необратимо'},
      ];

      for (const confirm of confirmations) {
        const result = await this.showConfirm(confirm.message, confirm.header);
        if (!result) {
          console.log('Действие отменено');
          this.$toast.add({severity: 'info', summary: `Действие отменено`, life: 3000});
          return;
        }
      }

      this.loading = true
      try {
        const response = await axios.delete(`${process.env.VUE_APP_BALANCER_API}/alphas/connect?id=${item.connection_id}`, {timeout: 20 * 1000})
        if (response.status === 204) {
          this.render()
          this.$toast.add({
            severity: 'info',
            summary: `Альфа ${item.alpha_name} была успешно отключена от канала - ${channel_name}`,
            life: 8000
          });
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: `Ошибка - ${error.response.data.error_code}`,
          detail: error.response.data.error_message,
          life: 3000
        });
      }
      this.loading = false
    },


    render() {
      this.$http.get(`${process.env.VUE_APP_BALANCER_API}/setups`, result => {
        this.table.data = result
        this.loading = false
      })
    },

    closePopup() {
      this.popup.setup = false
    },
  },

  mounted() {
    this.render()
  },
}
</script>

<style scoped>
.v-expansion-panels {
  gap: 10px;
}
</style>