var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultView',{attrs:{"title-page":"Каналы"},scopedSlots:_vm._u([{key:"right-title",fn:function(){return [_c('BigButton',{attrs:{"text-btn":"Добавить","icon-btn":"plus"},on:{"click":function($event){_vm.popup = true}}})]},proxy:true}])},[_c('BaseTable',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"data":_vm.data,"perPage":_vm.perPage,"pageCount_server":1,"callback_refresh":_vm.render},on:{"edit":_vm.editChannelEmit,"delete":_vm.deleteChannel},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return _vm.editChannelEmit(item)}}},[_c('v-list-item-title',[_vm._v(" Изменить ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteChannel(item)}}},[_c('v-list-item-title',[_vm._v(" Удалить ")])],1)]}},{key:"operators",fn:function(ref){
var item = ref.item;
return _vm._l((item.operators),function(val){return _c('div',{key:val},[(val == 'MTS')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_mts"}}),_vm._v(" MTS ")],1):_vm._e(),(val == 'Beeline')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_beeline"}}),_vm._v(" Beeline ")],1):_vm._e(),(val == 'Megafon')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_megafon"}}),_vm._v(" Megafon ")],1):_vm._e(),(val == 'Tele2')?_c('div',{staticClass:"operator_ico"},[_c('Icon',{attrs:{"name":"op_tele"}}),_vm._v(" Tele2 ")],1):_vm._e()])})}},{key:"status",fn:function(ref){
var item = ref.item;
return _vm._l((item.status),function(value){return _c('div',{key:value},[(value==0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Остановлено ")]):_vm._e(),(value==1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Работает ")]):_vm._e()],1)})}},{key:"alphas",fn:function(ref){
var item = ref.item;
return _vm._l((item.alphas),function(value){return _c('div',{key:value},[_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(value)+" ")])],1)})}}],null,true)}),_c('BaseModal',{attrs:{"show-modal":_vm.popup,"title":_vm.valuePopupChannel._id ? 'Редактировать канал' : 'Добавить канал'},on:{"close":_vm.closePopup,"clickBtn":_vm.save}},[_c('WrapperInputs',{attrs:{"title-inputs":"Оператор"}},[_c('v-select',{attrs:{"items":_vm.operators},model:{value:(_vm.valuePopupChannel.operator),callback:function ($$v) {_vm.$set(_vm.valuePopupChannel, "operator", $$v)},expression:"valuePopupChannel.operator"}})],1),_c('WrapperInputs',{attrs:{"title-inputs":"Канал"}},[_c('v-select',{attrs:{"items":_vm.channels},model:{value:(_vm.valuePopupChannel.channel),callback:function ($$v) {_vm.$set(_vm.valuePopupChannel, "channel", $$v)},expression:"valuePopupChannel.channel"}})],1),_c('pre',[_vm._v(_vm._s(_vm.alphas))]),_c('WrapperInputs',{attrs:{"title-inputs":"Альфы"}},[_c('v-select',{attrs:{"items":_vm.alphas,"multiple":""},model:{value:(_vm.valuePopupChannel.alphas),callback:function ($$v) {_vm.$set(_vm.valuePopupChannel, "alphas", $$v)},expression:"valuePopupChannel.alphas"}})],1),_c('WrapperInputs',{attrs:{"title-inputs":"Статус"}},[_c('v-select',{attrs:{"items":_vm.statuses,"item-value":"value","item-title":"name"},model:{value:(_vm.valuePopupChannel.status),callback:function ($$v) {_vm.$set(_vm.valuePopupChannel, "status", $$v)},expression:"valuePopupChannel.status"}})],1),_c('WrapperInputs',{attrs:{"title-inputs":"Цена"}},[_c('v-text-field',{attrs:{"placeholder":"Цена","type":"number"},model:{value:(_vm.valuePopupChannel.price),callback:function ($$v) {_vm.$set(_vm.valuePopupChannel, "price", $$v)},expression:"valuePopupChannel.price"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }